<template>
  <div class="footerBox">
    <div class="footer">
      <div class="footerMsg">
        <div class="footerLeft">
          <div class="footerLogo">
            <p>涌现（北京）文化产业有限公司</p>
            <img src="../../assets/stagePlay/图片1.jpg" alt="">
          </div>
          <ul class="footerContact">
            <li>服务时间:</li>
            <li>10:00-11:30 13:30-16:00</li>
            <li>公司地址:</li>
            <li>北京市大兴区魏善庄镇龙海路1号</li>
          </ul>
          <ul class="footerContact">
            <li>咨询热线:</li>
            <li>18612037698</li>
            <li>收件邮箱:</li>
            <li>3024562132@qq.com</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="footerTitle">
        <el-link class="linkItem" :href="item.src" v-for="(item,index) in linkData" :key="index" target="_blank">
          {{item.title}}
        </el-link>
      </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      linkData: [
        // {
        //   title: '京ICP备2023030198号',
        //   src: 'https://www.beian.gov.cn/portal/recordQuery'
        // },
        // {
        //   title: '|',
        //   // src: ''
        // },
        // {
        //   title: 'ICP证京B2-20210005',
        //   src: 'https://dxzhgl.miit.gov.cn/#/home'
        // },
        // {
        //   title: '|',
        //   // src: ''
        // },
        {
          title: '京ICP备2023030198号',
          src: 'https://beian.miit.gov.cn'
        }
      ],

    }
  },
}
</script>
<style scoped lang="scss">
.fixed{
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
.footerBox {
  background: #F6F9FF;
  .footer {
    max-width: 1040px;
    min-width: 1040px;
    margin: 0 auto;
    .footerMsg {
      height: 256px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: space-between;
      .footerLeft {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .footerLogo {
          width: 400px;
          line-height: 80px;
          img {
            width: 88px;
            height: 88px;
          }
          p {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            font-weight: bold;
            color: #181818;
          }
          .time {
            margin: 24px 0 10px 0;
          }
          span {
            color: #181818;
          }
        }
        .footerContact {
          text-align: left;
          margin-left: 100px;
          padding-top: 40px;
          width: 400px;
          margin-top: 29px;
          li:nth-of-type(odd) {
            font-size: 14px;
            font-weight: bold;
            color: #181818;
            font-weight: 400;
            color: #181818;
            line-height: 19px;
            margin-bottom: 10px;
          }
          li:nth-of-type(even) {
            color: #181818;
            margin-bottom: 20px;
          }
        }
      }
      .footerRight {
        width: 301px;
        display: flex;
        justify-content: space-between;
        .footerImgBox {
          width: 137px;
          margin-top: 44px;
          img {
            width: 137px;
            height: 137px;
          }
          span {
            color: #181818;
            display: block;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
    .footerLink {
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      p {
        font-size: 16px;
        font-weight: bold;
        font-weight: 600;
        color: #181818;
        margin: 29px 0 20px 0;
      }
      .linkItem {
        margin-right: 20px;
        color: #181818;
      }
    }
    ::v-deep.el-link::after {
      border: none;
    }
  }
}
.footerTitle {
  width: 100%;
  text-align: center;
  background-color: #EFF3FC;
  .linkItem {
    margin-right: 10px;
    color: #181818;
    font-size: 12px;
    line-height: 46px;
  }
}
</style>
