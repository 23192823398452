import request from "@/utils/request";  //导入封装请求的js文件

// 首页获取图文信息列表
export function getNoticeListApi (query) {
  return request({
    url: '/goodsApi/notice/list/v2',
    method: 'post',
    data: query,
  })
}
// 公告详情
export function getNoticeDetails (query) {
  return request({
    url: '/goodsApi/notice/details',
    method: 'post',
    data: query,
  })
}
// 推荐列表
export function getRecommendList (query) {
  return request({
    url: '/goodsApi/notice/recommend/list',
    method: 'post',
    data: query,
  })
}
//提交上架申请
export function submitUpShelf (query) {
  return request({
    url: '/assetsApi/pr/goods/goods/up/shelf/v3',
    method: 'post',
    data: query,
  })
}
//提交下架申请
export function submitOffShelf (query) {
  return request({
    url: '/assetsApi/pr/goods/goods/off/shelf/v3',
    method: 'post',
    data: query,
  })
}
//提交下架申请
export function getMenusList (query) {
  return request({
    url: '/userApi/menus/navigation/list',
    method: 'post',
    data: query,
  })
}

//首发
export function postVerlist (query) {
  return request({
    url: '/goodsApi/goods/ver/list/v3',
    method: 'post',
    data: query,
  })
}
//二手交易
export function postGoodslist (query) {
  return request({
    url: '/assetsApi/second/hand/goods/list',
    method: 'get',
    params: query,
  })
}
//二手交易详情
export function getDetails (query) {
  return request({
    url: '/assetsApi/second/hand/goods/details',
    method: 'get',
    params: query,
  })
}
//分类选择
export function getGoodsheat (params) {
  return request({
    url: '/assetsApi/pr/basic/type/list/v2',
    method: 'get',
    params: params,
  })
}
//详情
export function postVdetalis (query) {
  return request({
    url: '/goodsApi/goods/ver/details/v4',
    method: 'post',
    data: query,
  })
}
//推荐
export function postVlist (query) {
  return request({
    url: '/goodsApi/goods/index/list',
    method: 'post',
    data: query,
  })
}

//获取首页公告
export function getNotice (query) {
  return request({
    url: '/goodsApi/notice/list',
    method: 'post',
    data: query,
  })
}
//获取产品信息
export function getGoodsVer (query) {
  return request({
    url: '/goodsApi/goods/ver/goodsVer/details',
    method: 'post',
    data: query,
  })
}
//获取视频类型
export function getMediaCategory (query) {
  return request({
    url: '/goodsApi/goods/ver/details/category',
    method: 'post',
    data: query,
  })
}
//提交产品信息
export function updateGoodsVer (query) {
  return request({
    url: '/goodsApi/goods/ver/goodsVer/update',
    method: 'post',
    data: query,
  })
}

