import request from "@/utils/request";  //导入封装请求的js文件

//注册列表
export function getCopyrightApi (query) {
  return request({
    url: '/copyrightApi/cr/new/list',
    method: 'get',
    params: query,
  })
}


//提交审核
export function postSubmit (data) {
  return request({
    url: '/copyrightApi/copyright/examine/submit',
    method: 'post',
    data: data,
  })
}

//删除注册列表
export function postrolesDelete (data) {
  return request({
    url: '/copyrightApi/cr/new/delete',
    method: 'post',
    data: data,
  })
}

//查看本批次证书列表
export function getCopyrightList (query) {
  return request({
    url: '/copyrightApi/cr/new/cert/list',
    method: 'get',
    params: query,
  })
}
//查看作者/著作权人
export function getQueryPersonalIdentity (data) {
  return request({
    url: 'userApi/user/get_user_details_by_usn',
    method: 'post',
    data: data,
  })
}

//文件效验
export function getFileUploadApi (query) {
  return request({
    url: 'fileUploadApi/ws/file/check/v2',
    method: 'get',
    params: query,
  })
}
//文件上传
export function postUpload (data) {
  return request({
    url: '/fileUploadApi/ws/file/upload',
    method: 'post',
    data: data,
  })
}
//文件合并
export function postMerge (data) {
  return request({
    url: '/fileUploadApi/ws/file/merge',
    method: 'post',
    data: data,
  })
}
//获取详情
export function getDetails (query) {
  return request({
    url: '/copyrightApi/cr/new/details',
    method: 'get',
    params: query,
  })
}
export function getDetailsv (query) {
  return request({
    url: '/copyrightApi/cr/new/details/v2',
    method: 'get',
    params: query,
  })
}
//json保存
export function postSave (data) {
  return request({
    url: 'copyrightApi/cr/new/save',
    method: 'post',
    data: data,
  })
}
//提交审核
export function postSubmitcopyright (data) {
  return request({
    url: '/copyrightApi/copyright/examine/submit',
    method: 'post',
    data: data,
  })
}

//通过证件号搜索信息
export function getUserDetails (data) {
  return request({
    url: '/userApi/user/get_user_details_by_id_card_no',
    method: 'post',
    data: data,
  })
}

//判断是否可以填写登记
export function getsfStatus (query) {
  return request({
    url: '/copyrightApi/cr/new/status',
    method: 'get',
    params: query,
  })
}

//业务收费提交订单
export function postsubmitOrder (data) {
  return request({
    url: '/orderApi/order/business/service/submitOrder',
    method: 'post',
    data: data,
  })
}

//提交退款申请
export function postsubmitRefund (data) {
  return request({
    url: '/payApi/refund/submit',
    method: 'post',
    data: data,
  })
}

//查询订单支付状态
export function postqueryOrderPayStatus (data) {
  return request({
    url: '/orderApi/order/queryOrderPayStatus',
    method: 'post',
    data: data,
  })
}
//判断节点状态
export function getsqueryClientByUsn (query) {
  return request({
    url: '/apiAuth/clients/queryClientByUsn',
    method: 'get',
    params: query,
  })
}
//提交节点
export function postSaveOrUpdate (data) {
  return request({
    url: '/apiAuth/clients/saveOrUpdate',
    method: 'post',
    data: data,
  })
}
// 文化大数据跳转登录
export function getUserLogin (query) {
  return request({
    url: '/userApi/user/supernode/automatic/login',
    method: 'post',
    data: query,
  })
}