<template>
  <div class="overall-situation" v-loading="fullscreenLoading">
    <div class="situation">
      <div class="topBox">
        <span class="topTitle">版权注册</span>
      </div>
      <div class="medium">
        <el-button size="medium" icon="el-icon-plus" @click="registRation">新建注册</el-button>
      </div>
      <el-table :data="tableData" style="width: 100% margin-left:50px">
        <el-table-column prop="batchName" label="作品名称">
        </el-table-column>
        <el-table-column label="权利取得">
          <template slot-scope="scope">
            <span v-if="scope.row.copyrightRightsGet == 0">原创</span>
            <span v-else-if="scope.row.copyrightRightsGet == 1">有偿转让</span>
            <span v-else-if="scope.row.copyrightRightsGet == 2">继承</span>
            <span v-else-if="scope.row.copyrightRightsGet == 3">承受</span>
            <span v-else-if="scope.row.copyrightRightsGet == 4">无偿赠与</span>
            <span v-else-if="scope.row.copyrightRightsGet == 7">法人或者其他组织分立</span>
            <span v-else-if="scope.row.copyrightRightsGet == 8">法人或者其他组织合并</span>
            <span v-else>无</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTimes" label="日期">
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <template v-for="(item, index) in examineDate">
              <div class="statesBox" :key="index" v-if="scope.row.examineStatus == item.value">
                <div><i :class="[item.class, 'states']"></i>{{ item.label }}</div>
              </div>
            </template>
          </template>
        </el-table-column>
      <el-table-column label="操作"  width="230">
        <template slot-scope="scope">
          <el-button type="text"  @click="detailed(scope.row)"
          v-if="scope.row.examineStatus !== 4 ">
            详情
          </el-button>
          <el-button type="text"  @click="editMessage(scope.row)" 
          v-if="scope.row.examineStatus == 0 || scope.row.examineStatus == 4"
          >
            编辑
          </el-button>
          <el-button type="text"  @click="submitReview(scope.row)"
          v-if="scope.row.examineStatus == 0 "
          >
            提交审核
          </el-button>
          <!-- <el-button type="text"   @click="refundply(scope.row)"
          v-if="scope.row.examineStatus == 4 || scope.row.orderId == ''"
          >
            退款
          </el-button> -->
          <el-button type="text"   @click="viewCertificate(scope.row)"
          v-if="scope.row.examineStatus == 3"
          >
            查看证书
          </el-button>
          <el-button type="text"   @click="deLete(scope.row)"
          v-if="scope.row.examineStatus == 4 || scope.row.examineStatus == 0"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="query.currentPage"
      :page-sizes="[15, 30, 50, 100]"
      :page-size.sync="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
    <el-dialog title="证书列表" :visible.sync="dialogVisible" width="55%" :close-on-click-modal="false"
      :close-on-press-escape="false" center>
      <el-table :data="list">
        <el-table-column prop="worksName" label="作品名称">
        </el-table-column>
        <el-table-column label="作品类型">
          <template slot-scope="scope">
            {{ workTypeList[scope.row.worksType] }}
          </template>
        </el-table-column>
        <el-table-column label="创作性质">
          <template slot-scope="scope">
            {{ typeList[scope.row.worksCreation] || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="创作时间">
          <template slot-scope="scope">
            {{ scope.row.worksCreationDate || '无' }}
          </template>
        </el-table-column>
        <el-table-column label="创作地点">
          <template slot-scope="scope">
            {{ scope.row.worksCreationCountry }}-{{ scope.row.worksCreationCity }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button class="btnSpan" type="text" @click="seeBook(scope.row)">查看证书</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChangee" @current-change="handleCurrentChangee"
        :current-page.sync="merchandise.currentPage" :page-sizes="[15, 30, 50, 100]"
        :page-size.sync="merchandise.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totall">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="付款" :visible.sync="refundPopupWindow" :close-on-click-modal="false" @close='handleCancle'
      width="30%">
      <div class="Popup">
        <div class="Popup-type">
          <p class="p1">订单提交成功，请尽快付款</p>
          <p class="p2">订单号：{{ orderId }}</p>
          <p class="p3">应付金额：<span class="p3-a">{{ price / 100 }}</span><span class="p3-b">元</span></p>
          <p class="p4">付款方式：</p>
          <div class="p5" @click="payment(1)" :disabled="isDisable"><img src="../../assets/account/WeChat.png" alt="">微信支付
          </div>
          <div class="p6" @click="payment(2)" :disabled="isDisable"><img src="../../assets/account/Alipay.png"
              alt="">支付宝支付</div>
        </div>
        <div class="Popup-valuelist">
          <p class="p1"> 扫一扫付款（元）</p>
          <p class="p2">{{ price / 100 }}</p>
          <div class="p3">
            <img :src="payurl" alt="">
          </div>
          <p class="p4">打开手机{{ payname }}</p>
          <p class="p5">扫一扫继续付款</p>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="refundPopupWindow = false">取 消</el-button>
        <el-button type="primary" @click="refundPopupWindow = false">确 定</el-button>
      </span> -->
    </el-dialog>
    <el-dialog title="申请退款" :visible.sync="refundplyVisible" width="30%" center>
      <!-- <span>需要注意的是内容是默认不居中的</span> -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="退款原因" prop="refundReason">
          <el-input type="textarea" v-model="ruleForm.refundReason"></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="refundplyVisible = false">取 消</el-button>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('ruleForm')">申请退款</el-button>
        <el-button @click="refundplyVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCopyrightApi,
  postSubmit, postrolesDelete,
  getCopyrightList, postSubmitcopyright,
  getStatus, getsfStatus,
  postsubmitOrder,
  postsubmitRefund, postqueryOrderPayStatus

} from "@/api/index";
import QRCode from 'qrcode'
import debounce from '@/utils/debounce'
export default {
  // name: 'HomeView',
  components: {
    // HelloWorld
  },
  data () {
    return {
      file_url: 'https://files.wespace.cn',
      // file_url:'http://192.168.1.33',
      fullscreenLoading: false,
      dialogVisible: false,
      refundPopupWindow: false,
      refundplyVisible: false,
      payname: '微信',
      tableData: [],//注册列表
      list: [],//证书列表
      examineDate: [
        {
          label: '待提交',
          value: '0',
          class: 'statesSubmit'
        },
        {
          label: '待审核',
          value: '1',
          class: 'statesReviewed'
        },
        {
          label: '审核中',
          value: '2',
          class: 'statesConduct'
        },
        {
          label: '通过',
          value: '3',
          class: 'statesAdopt'
        },
        {
          label: '不通过',
          value: '4',
          class: 'statesFail'
        },
        {
          label: '执行中',
          value: '5',
          class: 'statesConduct'
        },
      ],
      query: {
        currentPage: 1,
        pageSize: 15,
        status: 1,
        usn: ''
      },
      merchandise: {
        currentPage: 1,
        pageSize: 15,
      },
      total: 0,
      totall: 0,
      typeList: {
        "0": "原创",
        "1": "转让",
        "2": "继承",
        "3": "承受",
        "4": "赠予",
        "5": "其他"
      },
      workTypeList: {
        1: "文字",
        2: "口述",
        3: "音乐",
        4: "戏剧",
        5: "曲艺",
        6: "舞蹈",
        7: "杂技",
        8: "美术",
        9: "建筑",
        10: "摄影",
        11: "电影",
        12: "类似摄制电影方法创作",
        13: "工程设计图、产品设计图",
        15: "地图、示意图",
        17: "模型",
        19: "录音制品",
        20: "录像制品",
        181: "其他作品"
      },
      payurl: '',
      price: '',
      orderId: '',
      isPay: '',
      orderStatus: '',
      alipayUrl: '',
      wecUrl: '',
      isDisable: false,
      rules: {
        refundReason: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      ruleForm: {
        refundReason: '',
        orderId: ''
      },
      status: '',
      timer: null,
      T: '',
      noClick:true,
    }
  },
  mounted () {
    this.query.usn = sessionStorage.getItem('usn')
    this.getList()
  },
  computed: {
    // 计算属性
    statusData () { return this.status }
  },
  watch: {
    statusData: function () {

      // console.log("kakn",this.status)
      //   // 当返回的新值为创建中的时候,保持3秒轮询
      //   if (this.status == 'creating') {
      //     console.log("newval1",this.status)
      //       this.timer = setInterval(() => {
      //           setTimeout(this.postStatus, 0)
      //       }, 3000)
      //   }
      //   // 当返回的新值为成功的时候,关闭定时器,结束轮询
      //   if (this.status == 'success') {
      //     console.log("newval12",this.status)
      //       clearInterval(this.timer)
      //       this.getList()
      //       this.$message({
      //         message: '支付成功',
      //         type: 'success'
      //       });
      //   }
      //   if (this.status == 'cancellation'){
      //     console.log("newval13",this.status)
      //       clearInterval(this.timer)
      //       this.getList()
      //       this.$message({
      //         message: '取消支付',
      //         type: 'warning'
      //       });
      //   }

      // 当页面关闭的时候,结束轮询,否则就会一直发请求,
      //使用$once(eventName, eventHandler)一次性监听事件
      // if(this.refundPopupWindow = false) {
      //   clearInterval(timer)
      // }
      // dialog.close(clearInterval(timer));

      this.$once('hook:beforeDestory', () => {

        clearInterval(this.timer)
      })
    }
  },
  methods: {
    //截取usn
    // 封装函数queryURLParams
    // @params:url
    // @return:参数
    queryURLParams () {
      // let href = 'http://localhost:8081/#/?usn=1ed9aa0184af1cfba659c1a3ee3121a02efde0ffb9066454deaa42ca831b4b7f&order_id=1111111';
      let href = window.location.href;
      // 截取问号后面的query参数
      let query = href.substring(href.indexOf("?") + 1);
      // 以&符号分割
      let item = query.split("&");
      let obj = {};
      for (let i = 0; i < item.length; i++) {
        let arr = item[i].split("=");
        // 参数名，参数值 赋值 对象的属性名，属性值
        obj[arr[0]] = arr[1];
      }
      if (obj.usn == undefined) {
        this.query.usn = sessionStorage.getItem('usn')
      } else if (obj.usn != '') {
        this.query.usn = obj.usn
        sessionStorage.setItem('usn', obj.usn)
        if (obj.order_id != undefined) {
          sessionStorage.setItem('order_id', obj.order_id)
        }
      }
      if (this.query.usn != undefined) {
        this.getList();
      }
      return obj;
    },
    //列表
    getList () {
      this.fullscreenLoading = true
      getCopyrightApi(this.query).then((response) => {
        if (response.code == 200) {
          this.fullscreenLoading = false
          this.tableData = response.data.list
          this.total = response.data.page.totalRows
        } else {
          this.fullscreenLoading = false
        }
      }).catch(err => {
        this.fullscreenLoading = false
        console.log(err)
      });
    },
    //新建注册
    registRation () {
      let order_id = sessionStorage.getItem('order_id')
      let usn = sessionStorage.getItem('usn')
      if (!sessionStorage.getItem('usnInfo')) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        })
        return
      }
      let usnInfo = JSON.parse(sessionStorage.getItem('usnInfo'))
      if (!usnInfo || !(usnInfo.user.identityAuthentication == '2')) {
        this.$message({
          message: '请先实名认证',
          type: 'warning'
        })
        return
      }
      this.$router.push({
        name: 'copyrightRegistration'
      })
    },
    //详情
    detailed (row) {
      // if (this.query.usn != '' && row.batchNo != '') {
        this.$router.push({
          name: 'copyrightRegistration',
          query: {
            batchNo: row.batchNo,
            showDetails: '1'
          }
        })
      // }
    },
    editMessage (row) {
      // if (this.query.usn != '' && row.batchNo != '') {
        this.$router.push({
          name: 'copyrightRegistration',
          query: {
            batchNo: row.batchNo
          }
        })
      // }
    },
    //提交审核
    submitReview (row) {
      if (this.noClick) {
        this.noClick = false;
        let unid = new FormData()
        unid.append('batchNo', row.batchNo)
        unid.append('taskCategory', 1)
        unid.append('orgId', '1394963863358668501')
        unid.append('createUsn', row.submitUsn)
        unid.append('createName', '')
        postSubmitcopyright(unid).then( res =>{
          if(res.code == 200) {
            this.$message({
              message: '提交审核成功',
              type: 'success'
            });
            this.getList()
          }else{
            this.$message({
              message: res.message,
              type: 'warning'
            });
          }
        }).catch(err=>{
          console.log(err)
        })
        setTimeout(function () {
          this.noClick = true;
        }, 3000)
      } else {
        console.log(444444444444)
      }
      // let unid = new FormData()
      // unid.append('batchNo', row.batchNo)
      // unid.append('businessType', '1')
      // unid.append('orderType', '15')
      // unid.append('buyerUsn', sessionStorage.getItem('usn'))
      // unid.append('payChannel', '1')
      // postsubmitOrder(unid).then( res =>{
      //   if(res.code == 200) {
      //     // let wecUrl = res.data.wechatQrCodeUrl; // 要生成二维码链接
      //     QRCode.toDataURL(res.data.wechatQrCodeUrl).then(url => {
      //       // 生成的二维码链接
      //       this.wecUrl = url
      //       this.payurl  = this.wecUrl
      //     })
      //     .catch(err => {
      //       console.error(err)
      //     })
      //     // let alipayUrl = res.data.alipayQrCodeUrl; // 要生成二维码链接
      //     QRCode.toDataURL(res.data.alipayQrCodeUrl).then(url => {
      //       // 生成的二维码链接
      //       this.alipayUrl = url
      //     })
      //     .catch(err => {
      //       console.error(err)
      //     })
      //     // this.payment(1)
      //     // console.log("this.payurl",this.payurl)
      //     // this.payurl  = this.wecUrl
      //     this.price = res.data.price
      //     this.orderId = res.data.orderId

      //     this.refundPopupWindow = true
      //     this.postStatus()
      //   }
      //   // this.payment(1)
      //   // console.log("this.payurl",this.payurl)
      // })
      
    },
    handleCancle () {
      clearInterval(this.T)
      this.$message({
        message: '取消支付',
        type: 'warning'
      });
      this.refundPopupWindow = false
    },
    payment (type) {
      if (type == 1) {
        this.payname = '微信'
        this.payurl = this.wecUrl
      } else if (type == 2) {
        this.payname = '支付宝'
        this.payurl = this.alipayUrl
      }
      console.log("this.payurl", this.payurl)
    },
    refundply (row) {
      this.ruleForm.orderId = row.orderId
      console.log("row", row)
      this.refundplyVisible = true
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          let unid = new FormData()
          unid.append('orderId', this.ruleForm.orderId)
          unid.append('createUsn', sessionStorage.getItem('usn'))
          unid.append('refundReason', this.ruleForm.refundReason)
          postsubmitRefund(unid).then(res => {
            if (res.code == 200) {
              this.$message({
                message: '提交退款审核成功',
                type: 'success'
              });
              this.refundplyVisible = false
              this.getList()
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    postStatus () {
      this.T = setInterval(() => {
        let unid = new FormData()
        unid.append('orderId', this.orderId)
        postqueryOrderPayStatus(unid).then(res => {
          if (res.code == 200) {
            this.orderStatus = res.data.orderStatus
            this.isPay = res.data.isPay
            if (res.data.isPay == '1' && res.data.orderStatus == '1') {
              clearInterval(this.T)
              this.getList()
              this.$message({
                message: '支付成功',
                type: 'success'
              });
            } else if (res.data.isPay == '0' && res.data.orderStatus == '2') {
              clearInterval(this.T)
              this.getList()
              this.$message({
                message: '取消支付',
                type: 'warning'
              });
            }
          }
        })
      }, 3000)
      // let unid = new FormData()
      // unid.append('orderId', this.orderId)
      // postqueryOrderPayStatus(unid).then( res =>{
      //   if(res.code == 200) {
      //     this.orderStatus = res.data.orderStatus
      //     this.isPay = res.data.isPay
      //     if(res.data.isPay == '1' && res.data.orderStatus == '1'){
      //       this.status = 'success'
      //     }else if(res.data.isPay == '0' && res.data.orderStatus == '2'){
      //       this.status = 'cancellation'
      //     }else{
      //       this.status = 'creating'
      //     }
      //     // orderStatus
      //     // isPay
      //     console.log("newval",this.status)
      //   }
      // })
    },
    //查看证书列表
    viewCertificate (row) {
      this.dialogVisible = true
      getCopyrightList({
        batchNo: row.batchNo,
        ...this.merchandise
      }).then((response) => {
        if (response.code == 200) {
          this.fullscreenLoading = false
          this.list = response.data.list
          this.totall = response.data.page.totalRows
        }
      });
    },
    // 查看通过的作品列表
    seeBook (item) {
      if (item.cerPath) {
        window.open(this.file_url + item.cerPath, "_blank");
      } else {
        this.$message({
          message: '证书生成中，请稍后',
          type: 'warning'
        });
      }
    },
    //删除
    deLete (row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let fd = new FormData();
        fd.append("batchNo", row.batchNo);
        postrolesDelete(fd).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    handleSizeChange (val) {
      this.query.pageSize = val;
      this.getList()
    },
    handleCurrentChange (val) {
      this.query.currentPage = val;
      this.getList()
    },
    handleSizeChangee (val) {
      this.merchandise.pageSize = val;
      // this.getList()
    },
    handleCurrentChangee (val) {
      this.merchandise.currentPage = val;
      // this.getList()
    },
  }
}
</script>
<style lang="scss" scoped>
.topBox {
  width: 72px;
  height: 24px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 24px;
  margin-bottom: 33px;
  .topTitle{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
  
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #EEEEEE;
}

.Popup {
  display: flex;

  .Popup-type {
    width: 50%;
    padding: 24px;
    background: #F7F8FA;
    border-radius: 8px 8px 8px 8px;

    .p1 {
      margin-bottom: 14px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #181818;
      line-height: 24px;
    }

    .p2 {
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }

    .p3 {
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;

      .p3-a {
        font-size: 16px;
        font-weight: 700;
        color: #EE6F2D;
        line-height: 24px;
      }

      .p3-b {
        margin-left: 3px;
        font-size: 12px;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
      }
    }

    .p4 {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }

    .p5 {
      margin-left: 3px;
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #181818;
      line-height: 24px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }

    .p6 {
      margin-left: 3px;
      margin-bottom: 14px;
      font-size: 14px;
      font-weight: 400;
      color: #181818;
      line-height: 24px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }

  .Popup-valuelist {
    text-align: center;
    margin: 0 auto;

    .p1 {
      padding-top: 13px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 24px;
    }

    .p2 {
      padding-top: 6px;
      font-size: 30px;
      font-weight: 700;
      color: #EE6F2D;
      line-height: 24px;
      padding-bottom: 27px;
    }

    .p3 {
      width: 120px;
      height: 120px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      background-color: #E6ECF2;
      border: 1px solid #F7F8FA;

      img {
        margin-top: 7px;
        width: 104px;
        height: 104px;
      }
    }

    .p4 {
      padding-top: 6px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }

    .p5 {
      padding-top: 6px;
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 18px;
    }
  }
}

.medium {
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
}

// ::v-deep .situation .el-table--fit{
//   margin-left: 30px;
// }
::v-deep .el-pagination {
  text-align: right;
  margin-top: 20px;
}

::v-deep .el-table__header-wrapper {
  background-color: #F7F8FA;

}

::v-deep .el-icon-plus {
  position: relative;
  top: -10px;
  color: #FFFFFF;
  margin-right: 5px;
  margin-left: -5px;
}

::v-deep .medium .el-button--medium {
  width: 102px !important;
  height: 28px !important;
  background: #004AFF !important;
  border: 1px solid #004AFF !important;
  opacity: 1;

  span {
    position: relative;
    top: -10px;
    left: 0px;
    font-size: 12px;
    margin: 0 auto;
    color: #FFFFFF;
    text-align: center;
    line-height: 28px;

    img {
      width: 12px;
      height: 12px;
      // margin-top: 1px;
    }
  }
}

.overall-situation {
  margin-top: 20px;

  // background: #F7F8FA;
  // min-height: 920px;
  // max-height: 1024px;
  .situation {
    margin: 0 auto;
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    // min-height: 765px;
    // height: 3825px;
    padding: 20px 20px 20px 20px;
    background: #FFFFFF;
    opacity: 1;

    .Group {
      display: flex;

      span {
        margin-left: 10px;
        // width: 96px;
        // height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        // line-height: 24px;
      }
    }
  }

  .statesBox {
    display: flex;
    justify-content: center;

    div {
      width: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .states {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 8px;
      float: left;
    }

    .statesAdopt {
      //状态-通过
      background: #2DA55D;
    }

    .statesFail {
      //状态-不通过
      background: #E05F42;
    }

    .statesSubmit {
      //状态-待提交
      background: #954EFF;
    }

    .statesReviewed {
      //状态-待审核
      background: #4E77FF;
    }

    .statesConduct {
      //状态-待审核
      background: #FFC300;
    }
  }

}
</style>
